import { Dispatch } from "redux"
import { toast } from "react-toastify"
import { pickBy } from "lodash"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

import API from "../../Api"
import AuthHelper from "../../helpers/AuthHelper"
import { SurveyCompleted, SurveyTabType, SurveyTypeEnum } from "../../views/app/surveys/types"
import { ReportRequestModuleEnum } from "../../api/reportrequest/reportrequest.dto"

dayjs.extend(utc)
dayjs.extend(timezone)

export interface SurveyTypesParams {
  caller?: string
  callee?: string
  dateFrom?: string
  dateTo?: string
  page?: number
  organizationId?: string
  organizationName?: string
  programId?: string
  programName?: string
  additionalComments?: string
  adminNotes?: string
  activeTab?: SurveyTabType
  actionRequired?: boolean
  isAnonymous?: boolean
  surveyType: SurveyTypeEnum
  changeOfCondition: string[]
}

export interface CallerSurveyResponsePayload {
  token: string
  respondentId: string | undefined
  answers: {
    questionId: string
    answer: any
  }[]
}

type FetchSurveyByType = (params: SurveyTypesParams) => (dispatch: Dispatch) => void
type FetchOneSurvey = (id: string) => (dispatch: Dispatch) => void
type MarkSurveyCompletedByType = (payload: SurveyCompleted, params: SurveyTypesParams) => (dispatch: Dispatch) => void
type ExportSurveyByType = (params: SurveyTypesParams) => (dispatch: Dispatch) => void
type ClearExportedSurveyByType = () => void
type PostponeAndFetchCallerSurvey = (id: string, date: string, params: SurveyTypesParams) => (dispatch: Dispatch) => void
type RespondCallerSurvey = (payload: CallerSurveyResponsePayload) => (dispatch: Dispatch) => void
type CreateReportRequest = (payload: SurveyTypesParams) => (dispatch: Dispatch) => void

export interface SurveysTypesActionsInterface {
  clearExportedSurveyByType: ClearExportedSurveyByType
  exportSurveyByType: ExportSurveyByType
  fetchAllCallerSurveys: FetchSurveyByType
  fetchActionRequiredSurveyByType: FetchSurveyByType
  markSurveyCompletedByType: MarkSurveyCompletedByType
  postponeAndFetchCallerSurveys: PostponeAndFetchCallerSurvey
  fetchOneCallerSurvey: FetchOneSurvey
  respondCallerSurvey: RespondCallerSurvey
  createReportRequest: CreateReportRequest
}

class SurveysTypesActions {
  public static FETCH_ALL_SURVEY_BY_TYPE_SUCCESS = "FETCH_ALL_SURVEY_BY_TYPE_SUCCESS"
  public static FETCH_ALL_SURVEY_BY_TYPE_TRIGGER = "FETCH_ALL_SURVEY_BY_TYPE_TRIGGER"
  public static FETCH_ALL_SURVEY_BY_TYPE_ERROR = "FETCH_ALL_SURVEY_BY_TYPE_ERROR"

  public static MARK_SURVEY_COMPLETED_SUCCESS = "MARK_SURVEY_COMPLETED_SUCCESS"
  public static MARK_SURVEY_COMPLETED_TRIGGER = "MARK_SURVEY_COMPLETED_TRIGGER"
  public static MARK_SURVEY_COMPLETED_ERROR = "MARK_SURVEY_COMPLETED_ERROR"

  public static FETCH_ACTION_REQUIRED_SURVEYS_SUCCESS = "FETCH_ACTION_REQUIRED_SURVEYS_SUCCESS"
  public static FETCH_ACTION_REQUIRED_SURVEYS_TRIGGER = "FETCH_ACTION_REQUIRED_SURVEYS_TRIGGER"
  public static FETCH_ACTION_REQUIRED_SURVEYS_ERROR = "FETCH_ACTION_REQUIRED_SURVEYS_ERROR"

  public static EXPORT_SURVEYS_TRIGGER = "EXPORT_CALLER_SURVEYS_TRIGGER"
  public static EXPORT_SURVEYS_SUCCESS = "EXPORT_CALLER_SURVEYS_SUCCESS"
  public static EXPORT_SURVEYS_ERROR = "EXPORT_CALLER_SURVEYS_ERROR"

  public static EXPORT_SURVEYS_CLEAR = "EXPORT_SURVEYS_CLEAR"

  public static POSTPONE_SURVEY_TRIGGER = "POSTPONE_SURVEY_TRIGGER"
  public static POSTPONE_SURVEY_SUCCESS = "POSTPONE_SURVEY_SUCCESS"
  public static POSTPONE_SURVEY_ERROR = "POSTPONE_SURVEY_ERROR"

  public static FETCH_ONE_SURVEY_SUCCESS = "FETCH_ONE_SURVEY_SUCCESS"
  public static FETCH_ONE_SURVEY_TRIGGER = "FETCH_ONE_SURVEY_TRIGGER"
  public static FETCH_ONE_SURVEY_ERROR = "FETCH_ONE_SURVEY_ERROR"

  public static RESPOND_CALLER_SURVEY_SUCCESS = "RESPOND_CALLER_SURVEY_SUCCESS"
  public static RESPOND_CALLER_SURVEY_TRIGGER = "RESPOND_CALLER_SURVEY_TRIGGER"
  public static RESPOND_CALLER_SURVEY_ERROR = "RESPOND_CALLER_SURVEY_ERROR"

  public static fetchAllSurveyByType: FetchSurveyByType = (params) => async (dispatch) => {
    dispatch({
      type: SurveysTypesActions.FETCH_ALL_SURVEY_BY_TYPE_TRIGGER,
    })
    try {
      const url = SurveysTypesActions.getUrlBySurveyType(params.surveyType)
      const callerSurveyType = SurveysTypesActions.getCallerSurveyType(params.surveyType)

      const payload = {
        ...params,
        ...(params.surveyType === SurveyTypeEnum.EVALUATION && params.changeOfCondition ? { type: params.changeOfCondition[0] } : {}),
        ...(callerSurveyType ? { callerSurveyType } : {}),
      }
      const {
        data: { data },
        status,
      } = await API.get(url, {
        params: payload,
        headers: AuthHelper.getAdminHeaders(),
      })

      if (status) {
        const surveys = [SurveyTypeEnum.CHANGE_OF_CONDITION, SurveyTypeEnum.POSTCALL_SURVEY, SurveyTypeEnum.INCALL_SURVEY, SurveyTypeEnum.INCALL_FOLLOWUP_SURVEY].includes(params.surveyType)
          ? data.callerSurveys
          : data.personOrganizationSurveys
        dispatch({
          payload: {
            data: surveys,
            pager: data.pager,
          },
          type: SurveysTypesActions.FETCH_ALL_SURVEY_BY_TYPE_SUCCESS,
        })
        return
      }
    } catch (err) {
      dispatch({
        payload: "Oops!... Looks like an error occurred!",
        type: SurveysTypesActions.FETCH_ALL_SURVEY_BY_TYPE_ERROR,
      })

      return
    }
  }

  public static fetchActionRequiredSurveyByType: FetchSurveyByType = (params) => async (dispatch) => {
    dispatch({
      type: SurveysTypesActions.FETCH_ACTION_REQUIRED_SURVEYS_TRIGGER,
    })
    try {
      const url = SurveysTypesActions.getUrlBySurveyType(params.surveyType)
      const callerSurveyType = SurveysTypesActions.getCallerSurveyType(params.surveyType)
      const payload = {
        ...params,
        ...(params.surveyType === SurveyTypeEnum.EVALUATION && params.changeOfCondition ? { type: params.changeOfCondition[0] } : {}),
        ...(callerSurveyType ? { callerSurveyType } : {}),
        actionRequired: true,
      }
      const {
        data: { data },
        status,
      } = await API.get(url, {
        params: payload,
        headers: AuthHelper.getAdminHeaders(),
      })

      if (status) {
        const surveys = [SurveyTypeEnum.CHANGE_OF_CONDITION, SurveyTypeEnum.POSTCALL_SURVEY, SurveyTypeEnum.INCALL_SURVEY].includes(params.surveyType)
          ? data.callerSurveys
          : data.personOrganizationSurveys
        dispatch({
          payload: {
            data: surveys,
            pager: data.pager,
          },
          type: SurveysTypesActions.FETCH_ACTION_REQUIRED_SURVEYS_SUCCESS,
        })

        return
      }
    } catch (err) {
      dispatch({
        payload: "Oops!... Looks like an error occurred!",
        type: SurveysTypesActions.FETCH_ACTION_REQUIRED_SURVEYS_ERROR,
      })

      return
    }
  }

  public static markSurveyCompletedByType: MarkSurveyCompletedByType = (payload, params) => async (dispatch) => {
    dispatch({
      type: SurveysTypesActions.MARK_SURVEY_COMPLETED_TRIGGER,
    })
    try {
      const {
        data: { data },
        status,
      } = await API.patch(
        `/v2/callerSurveys/${payload.id}/completed`,
        {
          ...payload,
        },
        {
          headers: AuthHelper.getAdminHeaders(),
        },
      )

      if (!status) {
        dispatch({
          payload: "Oops!... Looks like an error occurred!",
          type: SurveysTypesActions.MARK_SURVEY_COMPLETED_ERROR,
        })
        return
      }
      SurveysTypesActions.fetchActionRequiredSurveyByType(params)(dispatch)
      SurveysTypesActions.fetchAllSurveyByType(params)(dispatch)
      dispatch({
        payload: data,
        type: SurveysTypesActions.MARK_SURVEY_COMPLETED_SUCCESS,
      })
    } catch (err) {
      dispatch({
        payload: "Oops!... Looks like an error occurred!",
        type: SurveysTypesActions.MARK_SURVEY_COMPLETED_ERROR,
      })

      return
    }
  }

  public static exportSurveyByType: ExportSurveyByType = (params) => async (dispatch) => {
    try {
      dispatch({
        type: SurveysTypesActions.EXPORT_SURVEYS_TRIGGER,
      })

      const url = SurveysTypesActions.getUrlBySurveyType(params.surveyType)
      const callerSurveyType = SurveysTypesActions.getCallerSurveyType(params.surveyType)
      const queryParams = {
        ...params,
        ...(callerSurveyType ? { callerSurveyType } : {}),
      }
      const res = await API.get(`${url}/csv`, {
        params: queryParams,
        headers: AuthHelper.getAdminHeaders(),
        responseType: "blob",
      })

      dispatch({
        type: SurveysTypesActions.EXPORT_SURVEYS_SUCCESS,
        payload: res,
      })

      return
    } catch (err) {
      dispatch({
        type: SurveysTypesActions.EXPORT_SURVEYS_ERROR,
      })

      return
    }
  }

  public static clearExportedSurveyByType = () => (dispatch: Dispatch) => {
    dispatch({
      type: SurveysTypesActions.EXPORT_SURVEYS_CLEAR,
    })
  }

  public static postponeAndFetchCallerSurveys = (id: string, date: string, params: SurveyTypesParams) => async (dispatch: Dispatch) => {
    dispatch({
      type: SurveysTypesActions.POSTPONE_SURVEY_TRIGGER,
    })

    try {
      const {
        data: { data },
        status,
      } = await API.patch(`/v2/personorganizationssurveys/${id}/postpone`, { date }, { headers: AuthHelper.getAdminHeaders() })

      if (status) {
        dispatch({
          payload: data,
          type: SurveysTypesActions.POSTPONE_SURVEY_SUCCESS,
        })

        SurveysTypesActions.fetchActionRequiredSurveyByType(params)(dispatch)
      }
      return
    } catch (err) {
      dispatch({
        payload: "Oops!... Looks like an error occurred!",
        type: SurveysTypesActions.POSTPONE_SURVEY_ERROR,
      })
    }
  }

  public static fetchOneCallerSurvey: FetchOneSurvey = (id) => async (dispatch) => {
    dispatch({
      type: SurveysTypesActions.FETCH_ONE_SURVEY_TRIGGER,
    })
    try {
      const url = SurveysTypesActions.getUrlBySurveyType(SurveyTypeEnum.INCALL_SURVEY)
      const {
        data: { data },
        status,
      } = await API.get(`${url}/${id}`, {
        headers: AuthHelper.getAdminHeaders(),
      })

      if (status) {
        dispatch({
          payload: {
            data: data,
            pager: data.pager,
          },
          type: SurveysTypesActions.FETCH_ONE_SURVEY_SUCCESS,
        })
        return
      }
    } catch (err) {
      dispatch({
        payload: "Oops!... Looks like an error occurred!",
        type: SurveysTypesActions.FETCH_ONE_SURVEY_ERROR,
      })

      return
    }
  }

  public static respondCallerSurvey = (payload: CallerSurveyResponsePayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: SurveysTypesActions.RESPOND_CALLER_SURVEY_TRIGGER,
      })
      const {
        data: { data },
        status,
      } = await API.post(
        `/v2/callerSurveys/answers/${payload.token}`,
        {
          ...payload,
        },
        {
          headers: AuthHelper.getAdminHeaders(),
        },
      )

      if (!status) {
        throw new Error("Oops!... Looks like an error occurred!")
      }
      dispatch({
        payload: data,
        type: SurveysTypesActions.RESPOND_CALLER_SURVEY_SUCCESS,
      })
    } catch (err) {
      dispatch({
        payload: "Oops!... Looks like an error occurred!",
        type: SurveysTypesActions.RESPOND_CALLER_SURVEY_ERROR,
      })
    }
  }

  private static getUrlBySurveyType = (surveyType: SurveyTypeEnum) => {
    return [SurveyTypeEnum.CHANGE_OF_CONDITION, SurveyTypeEnum.POSTCALL_SURVEY, SurveyTypeEnum.INCALL_SURVEY, SurveyTypeEnum.INCALL_FOLLOWUP_SURVEY].includes(surveyType)
      ? "/v2/callerSurveys"
      : "/v2/personorganizationssurveys"
  }

  private static getCallerSurveyType = (surveyType: SurveyTypeEnum) => {
    const isValidCallerSurveyType = [SurveyTypeEnum.CHANGE_OF_CONDITION, SurveyTypeEnum.POSTCALL_SURVEY, SurveyTypeEnum.INCALL_SURVEY, SurveyTypeEnum.INCALL_FOLLOWUP_SURVEY].includes(surveyType)
    if (!isValidCallerSurveyType) {
      return undefined
    }
    if (surveyType === SurveyTypeEnum.CHANGE_OF_CONDITION) {
      return SurveyTypeEnum.POSTCALL_SURVEY
    }
    return surveyType
  }

  public static createReportRequest: CreateReportRequest =
    ({ organizationId, programId, organizationName, programName, surveyType, caller, callee, dateFrom, dateTo, actionRequired, additionalComments, adminNotes, isAnonymous }) =>
    async (dispatch) => {
      dispatch({
        type: SurveysTypesActions.EXPORT_SURVEYS_TRIGGER,
      })

      const module = ReportRequestModuleEnum.SURVEY_COC

      try {
        const now = new Date().getTime()
        const { userId } = AuthHelper.getUserAuth()
        const payload = pickBy(
          {
            module,
            requestedById: userId,
            organizationId: organizationId || null,
            organizationName: organizationName || null,
            filters: {
              tz: dayjs.tz.guess(),
              startDate: new Date(dateFrom as string).getTime(),
              endDate: new Date(dateTo as string).getTime(),
              programId: programId || null,
              programName: programName || null,
              params: {
                ...(caller ? { caller } : {}),
                ...(callee ? { callee } : {}),
                ...(actionRequired !== undefined ? { actionRequired } : {}),
                ...(isAnonymous !== undefined ? { isAnonymous } : {}),
                ...(additionalComments ? { additionalComments } : {}),
                ...(adminNotes ? { adminNotes } : {}),
              },
            },
          },
          (value) => value !== null,
        )
        await API.post("/v2/reportrequest/create", payload, {
          headers: AuthHelper.getAdminHeaders(),
        })

        dispatch({
          type: SurveysTypesActions.EXPORT_SURVEYS_SUCCESS,
        })
        toast("Report request created successfully, you will be notified when it's ready for download", { type: "success" })
        return
      } catch (err) {
        toast("Error creating report request, try again later", { type: "error" })
        dispatch({
          type: SurveysTypesActions.EXPORT_SURVEYS_ERROR,
        })

        return
      }
    }
}

export default SurveysTypesActions
